@import "~@angular/material/prebuilt-themes/purple-green.css";
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #0C0C0D;
  color: white;
}

@font-face {
  font-family: "Rowdies";
  src: local("Rowdies"), url(./assets/fonts/Rowdies/Rowdies-Regular.ttf) format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Rowdies";
  src: local("Rowdies"), url(./assets/fonts/Rowdies/Rowdies-Bold.ttf) format("truetype");
  font-style: bold;
}
@font-face {
  font-family: "Play";
  src: local("Play"), url(./assets/fonts/Play/Play-Regular.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Play";
  src: local("Play"), url(./assets/fonts/Play/Play-Bold.ttf) format("truetype");
  font-weight: bold;
}

.carousel-control-next, .carousel-control-prev
{
  bottom: 80px!important;
  top: 80px!important;
}

.purple-snackbar {
  background: #7b1fa2;
  color: white;
}

.mat-progress-bar-buffer
{
  background-color: #391140;
}
